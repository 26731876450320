import React from "react"
import {graphql} from "gatsby"
import {Helmat} from "../components/utils";
import Navigation from "../components/navigation";
import * as Utils from "../components/utils";


export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date
      }
      html
    }
  }
`

const Blog = (props) => {
    return (

        <main>
            <Helmat title="Home"/>
            <Navigation/>
            <Utils.StageLogo/>
            <div className="article-container">
                <div className="article">

                    <h1 className="article-title">{props.data.markdownRemark.frontmatter.title}</h1>
                    {/*<p>{props.data.markdownRemark.frontmatter.date}</p>*/}
                    <div dangerouslySetInnerHTML={{__html: props.data.markdownRemark.html}}/>

                </div>
            </div>
        </main>
    )
}

export default Blog
